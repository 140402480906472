<template>
    <div class="d-flex justify-content-center">
        <b-card class="mb-2" :disabled="isDisabled">
            <b-card-title class="d-flex justify-content-between">
                Glömt lösenord <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading"></b-icon>
            </b-card-title>
            <b-card-text>
                <label for="loginInputEmail">Epostadress:</label>
                <b-form-input type="email" id="forgotInputEmail" v-model="email"
                    placeholder="namn@adress.se"></b-form-input>
                <div v-if="message.length > 0" class="mt-3 text-danger"><small>{{ message }}</small></div>
            </b-card-text>
            <template #footer>
                <div class="responsive-footer">
                    <b-button variant="success" @click="forgotClicked()" :disabled="forgotSent">Glömt lösenord</b-button>
<!--                     <b-button variant="primary" class="mt-2 mt-sm-2 mt-md-0 mt-lg-0 mt-xl-0" @click="switchToLogin()">Logga in &nbsp;&nbsp;<b-icon
                            icon="arrow-right-circle" aria-hidden="true"></b-icon></b-button> -->
                </div>
            </template>
        </b-card>
    </div>
</template>

<style lang="css" scoped>
@media (min-width: 768px) {

    /* For desktop: */
    .card {
        min-width: 600px;
    }
    .responsive-footer{
        display: flex;
        justify-content:flex-end;
    }
}
@media (max-width: 768px) {
    /* For mobile: */
    .card {
        min-width: 300px;
    }
    .responsive-footer{
        display: grid;
    }
}
.card-body {
    background: #f1f0e6;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    data: () => ({
        //flags
        isDisabled: false,
        isLoading: false,

        // form values
        email: '',
        forgotSent: false,
        message: '',

        //rules
        emailRules: [
            v => /.+@.+/.test(v) || 'E-post är inte giltig',
        ],
    }),
    methods: {
        ...mapActions({
            // vuex module/ActionName
            forgot: 'authentication/forgot',
            getProfile: 'authentication/getProfile'
        }),

        switchToLogin() {
            this.$emit('switchToLogin')
        },

        forgotClicked() {
            if (this.email.length > 0) { // validation?
                this.forgot({ email: this.email }).then((result) => {
                    //console.log(result);
                    if (result.data.success) {
                        this.email = '';
                    }
                    this.message = result.data.message;
                });
            }else {
                this.message = "Fyll i e-post!";
            }
        },
    },
    mounted() {
        this.email = '';
        this.forgotSent = false;
    }
}
</script>